<i18n>
{
  "de": {
    "pageTitle": "Import",
    "overwritePortfolioTitle": "Portfolio überschreiben",
    "importBuildingsTitle": "Liegenschaften importieren",
    "importValuesTitle": "Verbrauchswerte importieren",
    "importIndicatorsTitle": "Emissionsfaktoren importieren"
  }
}
</i18n>
<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="import-page">
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />
    </template>

    <template #default>
      <!-- disabled because the workflow is broken -->
      <!-- <h2 v-if="getPortfolioPermission('UPDATE')">{{ $t('overwritePortfolioTitle') }}</h2>-->
      <!-- <ImportPortfolio v-if="getPortfolioPermission('UPDATE')" :portfolio="portfolio" />-->
      <h2>{{ $t('importBuildingsTitle') }}</h2>
      <ImportBuildings class="import-component" :portfolio="portfolio" />
      <h2>{{ $t('importValuesTitle') }}</h2>
      <ImportValues class="import-component" :portfolio="portfolio" :buildings="buildingsForList" />
      <h2>{{ $t('importIndicatorsTitle') }}</h2>
      <ImportIndicators class="import-component" :portfolio="portfolio" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import ImportIndicators from '@/components/portfolio/ImportIndicators.vue'
import ImportBuildings from '@/components/portfolio/ImportBuildings.vue'
import ImportValues from '@/components/portfolio/ImportValues.vue'
import PortfolioBuildingsForListMixin from '@/pages/vuex-mixins/PortfolioBuildingsForListMixin.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

export default {
  name: 'import',

  mixins: [
    //
    AllPortfoliosMixin,
    PortfolioMixin,
    PortfolioBuildingsForListMixin,
  ],

  components: {
    MainLayout,
    ImportBuildings,
    ImportValues,
    ImportIndicators,
    PageTitle,
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style>
.import-component {
  margin-bottom: 20px;
}
</style>
